.navbar{
    background-color: #0E101C;
    font-size: 12px;
    font-family: 'Press Start 2P', cursive;
    float: right;
}
.navbarkids{
    margin-left: 25px;
    color: white;
    text-decoration: none;
}
.navbarkids:hover{
    color:yellow;
    cursor:pointer;
}